import { lazy } from 'react';

// project imports
import MainLayout from '../components/menu-gym-basic';
import Loadable from '../components/Loadable';
import { ProtectedRoute } from "./ProtectedRoute";

//import { mainListItems_o } from './opengym/list-menu-admin';
//import { mainListItems_g } from './gym/list-menu-admin';
//import { mainListItems_u } from './user/list-menu-admin';

const PanelCheckin = Loadable(lazy(() => import('../pages/admin/gym/checkinPanel')));
const CheckinsUser = Loadable(lazy(() => import('../pages/admin/gym/checkinPanel/checkinPanel.user')));

// ==============================|| MAIN ROUTING ||============================== //
// Validação do Token
// source = 1 - Area OpenGym
// source = 2 - Area Academia Adm
// source = 3 - Area Aluno

const GymBasicRoute = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/gym/painel/:idUsuario',
            element: <ProtectedRoute source='2'><PanelCheckin /></ProtectedRoute>
        },
        {
            path: '/gym/checkinPanel/user/:idUsuario/:idGymUser',
            element: <ProtectedRoute source='2'><CheckinsUser /></ProtectedRoute>
        },
    ]
};

export default GymBasicRoute;
